import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindCartProductsToRepeatOrderV2QueryVariables = Types.Exact<{
  orderId: Types.Scalars['Int'];
  storeId: Types.Scalars['Int'];
}>;


export type FindCartProductsToRepeatOrderV2Query = { __typename?: 'Query', findCartProductsToRepeatOrderV2: { __typename?: 'RepeatOrderResponseV2', status: Types.RepeatOrderStatus, items: Array<{ __typename?: 'RepeatOrderItemV2', quantity: number, status: Types.RepeatOrderItemStatus, product: { __typename?: 'ListingProductV2', id: number, name: string, image?: string | null, brand: string, description?: string | null, notification: boolean, category: { __typename?: 'ListingCategory', id: number, name: string, image?: string | null }, displays: Array<{ __typename?: 'ListingDisplayV2', id: number, default: boolean, units: number, storeUnavailabiliy: boolean, price: { __typename?: 'Money', amount: number, currency: string, scale: number }, unitPrice: { __typename?: 'Money', amount: number, currency: string, scale: number } }> } }> } };


export const FindCartProductsToRepeatOrderV2Document = gql`
    query FindCartProductsToRepeatOrderV2($orderId: Int!, $storeId: Int!) {
  findCartProductsToRepeatOrderV2(orderId: $orderId, storeId: $storeId) {
    status
    items {
      quantity
      product {
        id
        name
        image
        brand
        description
        notification
        category {
          id
          name
          image
        }
        displays {
          id
          default
          units
          price {
            amount
            currency
            scale
          }
          unitPrice {
            amount
            currency
            scale
          }
          storeUnavailabiliy
        }
      }
      status
    }
  }
}
    `;

/**
 * __useFindCartProductsToRepeatOrderV2Query__
 *
 * To run a query within a React component, call `useFindCartProductsToRepeatOrderV2Query` and pass it any options that fit your needs.
 * When your component renders, `useFindCartProductsToRepeatOrderV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCartProductsToRepeatOrderV2Query({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useFindCartProductsToRepeatOrderV2Query(baseOptions: Apollo.QueryHookOptions<FindCartProductsToRepeatOrderV2Query, FindCartProductsToRepeatOrderV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindCartProductsToRepeatOrderV2Query, FindCartProductsToRepeatOrderV2QueryVariables>(FindCartProductsToRepeatOrderV2Document, options);
      }
export function useFindCartProductsToRepeatOrderV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindCartProductsToRepeatOrderV2Query, FindCartProductsToRepeatOrderV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindCartProductsToRepeatOrderV2Query, FindCartProductsToRepeatOrderV2QueryVariables>(FindCartProductsToRepeatOrderV2Document, options);
        }
export type FindCartProductsToRepeatOrderV2QueryHookResult = ReturnType<typeof useFindCartProductsToRepeatOrderV2Query>;
export type FindCartProductsToRepeatOrderV2LazyQueryHookResult = ReturnType<typeof useFindCartProductsToRepeatOrderV2LazyQuery>;
export type FindCartProductsToRepeatOrderV2QueryResult = Apollo.QueryResult<FindCartProductsToRepeatOrderV2Query, FindCartProductsToRepeatOrderV2QueryVariables>;