import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindPromotionByIdForStoreV2QueryVariables = Types.Exact<{
  input: Types.FindPromotionByIdInput;
}>;


export type FindPromotionByIdForStoreV2Query = { __typename?: 'Query', findPromotionByIdForStoreV2: { __typename?: 'PromotionDetailV2', id: number, type: Types.PromotionType, title?: string | null, description?: string | null, image?: string | null, pillInfo: { __typename?: 'PillPromotionInfo', pillType: Types.PillType, text: string, promotionId?: number | null }, products: Array<{ __typename?: 'ProductDetailInPromotion', id: number, name: string, description: string, image?: string | null, boxRatio: number, brand: string, notification: boolean, images?: Array<{ __typename?: 'ProductImage', id: number, image: string, default: boolean }> | null, displays: Array<{ __typename?: 'DisplayProductDetailInPromotion', id: number, productId: number, default: boolean, units: number, storeUnavailabiliy: boolean, price: { __typename?: 'Money', amount: number, currency: string, scale: number }, unitPrice: { __typename?: 'Money', amount: number, currency: string, scale: number } }> }>, discountTiers?: Array<{ __typename?: 'DiscountTier', id: number, min: number, max?: number | null, discountPercentage?: number | null, discountAmount?: number | null, currency?: string | null, scale?: number | null, tierType: Types.DiscountTierType }> | null, bonusTier?: { __typename?: 'BonusTierDetail', id: number, quantityRequired: number, rewardedProductDetails: { __typename?: 'RewardedProductDetails', productId: number, productImage?: string | null, productName: string, productDescription: string, displayProductId: number, displayProductUnits: number, rewardedQuantity: number } } | null } };


export const FindPromotionByIdForStoreV2Document = gql`
    query FindPromotionByIdForStoreV2($input: FindPromotionByIdInput!) {
  findPromotionByIdForStoreV2(input: $input) {
    id
    type
    pillInfo {
      pillType
      text
      promotionId
    }
    title
    description
    image
    products {
      id
      name
      description
      image
      boxRatio
      images {
        id
        image
        default
      }
      brand
      notification
      displays {
        id
        productId
        default
        units
        price {
          amount
          currency
          scale
        }
        unitPrice {
          amount
          currency
          scale
        }
        storeUnavailabiliy
      }
    }
    discountTiers {
      id
      min
      max
      discountPercentage
      discountAmount
      currency
      scale
      tierType
    }
    bonusTier {
      id
      quantityRequired
      rewardedProductDetails {
        productId
        productImage
        productName
        productDescription
        displayProductId
        displayProductUnits
        rewardedQuantity
      }
    }
  }
}
    `;

/**
 * __useFindPromotionByIdForStoreV2Query__
 *
 * To run a query within a React component, call `useFindPromotionByIdForStoreV2Query` and pass it any options that fit your needs.
 * When your component renders, `useFindPromotionByIdForStoreV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPromotionByIdForStoreV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindPromotionByIdForStoreV2Query(baseOptions: Apollo.QueryHookOptions<FindPromotionByIdForStoreV2Query, FindPromotionByIdForStoreV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindPromotionByIdForStoreV2Query, FindPromotionByIdForStoreV2QueryVariables>(FindPromotionByIdForStoreV2Document, options);
      }
export function useFindPromotionByIdForStoreV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindPromotionByIdForStoreV2Query, FindPromotionByIdForStoreV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindPromotionByIdForStoreV2Query, FindPromotionByIdForStoreV2QueryVariables>(FindPromotionByIdForStoreV2Document, options);
        }
export type FindPromotionByIdForStoreV2QueryHookResult = ReturnType<typeof useFindPromotionByIdForStoreV2Query>;
export type FindPromotionByIdForStoreV2LazyQueryHookResult = ReturnType<typeof useFindPromotionByIdForStoreV2LazyQuery>;
export type FindPromotionByIdForStoreV2QueryResult = Apollo.QueryResult<FindPromotionByIdForStoreV2Query, FindPromotionByIdForStoreV2QueryVariables>;